@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Unbounded:wght@400;500;600;700&display=swap');

.App {
  font-family: 'Manrope', sans-serif;
}

.title {
  font-family: 'Unbounded', sans-serif;
}


.separator-color {
  border-color: rgba(255, 255, 255, 0.1);
}